<template>
  <form
    class="mt-6"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <fieldset
      :disabled="submitting"
    >
      <claim-form-fields
        :resource="resource"
      />

      <app-button
        feature="save"
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import ClaimFormFields from '@extranet/components/resources/claim/ClaimFormFields.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not
  // generated by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })
</script>
