<template>
  <teleport-wrapper
    v-if="claim && !store.state.spinner.active"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <template
    v-if="!store.state.spinner.active"
  >
    <claim-form
      v-if="claim"
      :resource="claim"
      :additional-errors="claimFormErrors"
      :submitting="claimFormSubmitting"
      @submitted="handleSubmit"
    />
  </template>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import {
  fetchClaim as fetchApiClaim,
  updateClaim,
} from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import breadcrumbClaim from '@extranet/breadcrumbs/claim'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import ClaimForm from '@extranet/components/resources/claim/ClaimForm.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ---------- CLAIM ----------

const claimFormSubmitting = ref(false)
const claim = ref({})
const claimFormErrors = ref({})

async function fetchClaim() {
  const { id } = route.params

  await fetchApiClaim(id)
    .then((response) => {
      claim.value = response.data.data
    })
}

// ---------- POI ----------

const pointOfInterest = computed(() => (
  claim.value?.relations?.point_of_interest
))

// ---------- FORM ----------

function handleSubmit(attributes) {
  if (!claimFormSubmitting.value) {
    claimFormSubmitting.value = true

    const params = {
      data: {
        type: 'claim',
        attributes: { ...attributes },
      },
    }

    updateClaim(claim.value?.id, params)
      .then((response) => {
        router.push({
          name: 'show-claims',
          params: { id: response.data.data?.id },
        })

        store.commit(
          'flashes/ADD_FLASH',
          {
            message: t('ttmt.claims.update.success'),
            type: 'success',
          },
        )
      })
      .catch((e) => {
        if (e.response?.data?.errors) {
          claimFormErrors.value = e.response.data.errors
        }
      })
      .finally(() => {
        claimFormSubmitting.value = false
      })
  }
}

// ---------- BREADCRUMBS ----------

const { edit } = breadcrumbClaim()

const breadcrumbs = computed(() => (
  edit({
    id: claim.value?.id,
    label: getAttributeTranslation(pointOfInterest.value?.attributes?.title),
  })
))

// ---------- LIFECYCLES ----------

onMounted(async () => {
  store.commit('spinner/ENABLE')
  await fetchClaim()
  store.commit('spinner/DISABLE')
})
</script>
