<template>
  <!-- Actions -->
  <claim-actions
    :resource="resource"
    show-labels
    class="mb-4"
  />

  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div>
      <!-- Claim -->
      <app-section-title
        :title="t('ttmt.claims.pages.show.sections.claim')"
        class="mb-3.5"
      />

      <app-card-item class="p-4">
        <app-data-grid
          :data="claimData"
          :loading="loading"
        />
      </app-card-item>
    </div>

    <div>
      <!-- Points of interest -->
      <app-section-title
        :title="t('ttmt.claims.pages.show.sections.poi')"
        class="mb-3.5"
      />

      <app-card-item class="p-4">
        <point-of-interest-card
          :resource="pointOfInterest"
          hide-actions
        />
      </app-card-item>

      <!-- User -->
      <app-section-title
        :title="t('ttmt.claims.pages.show.sections.user')"
        class="mb-3.5 mt-6"
      />

      <app-card-item class="p-3">
        <user-card
          :resource="user"
        />
      </app-card-item>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useClaim from '@extranet/hooks/claim'
import useDatetime from '@shared/hooks/datetime'
import AppDataGrid from '@extranet/components/ui/data_grid/AppDataGrid.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppSectionTitle from '@extranet/components/ui/AppSectionTitle.vue'
import AppDataGridLink from '@extranet/components/ui/data_grid/AppDataGridLink.vue'
import PointOfInterestCard from '@extranet/components/resources/point_of_interest/PointOfInterestCard.vue'
import UserCard from '@extranet/components/resources/user/UserCard.vue'
import ClaimActions from '@extranet/components/resources/claim/ClaimActions.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is it loading?
  loading: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()
const {
  formatDateWithTime,
} = useDatetime()

// ---------- CLAIM ----------

const { statusBadgeDesign } = useClaim()

const claimData = computed(() => {
  const claim = props.resource

  return [
    {
      label: t('validation.attributes.email'),
      component: AppDataGridLink,
      props: {
        type: 'email',
        link: claim?.attributes?.email,
      },
    },
    {
      label: t('validation.attributes.phone'),
      component: AppDataGridLink,
      props: {
        type: 'phone',
        link: claim?.attributes?.phone,
      },
    },
    {
      label: t('validation.attributes.siret'),
      data: claim?.attributes?.siret,
    },
    {
      label: t('validation.attributes.state'),
      component: AppStatusBadge,
      props: {
        design: statusBadgeDesign(claim.attributes?.state),
        text: t(`ttmt.claims.states.${claim.attributes?.state}`),
      },
    },
    {
      label: t('validation.attributes.validated_at'),
      data: claim.attributes?.validated_at ?? null
        ? formatDateWithTime(claim.attributes?.validated_at)
        : null,
    },
    {
      label: t('validation.attributes.denial_reason'),
      data: claim?.attributes?.denial_reason,
    },
  ]
})

// ---------- POINT OF INTEREST ----------

const pointOfInterest = computed(() => (
  props.resource?.relationships?.point_of_interest
))

// ---------- USER ----------

const user = computed(() => (
  props.resource?.relationships?.user
))
</script>
