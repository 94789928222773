import { useI18n } from 'vue-i18n'

import useBreadcrumb from '@shared/hooks/breadcrumbs'

export default function breadcrumbClaim() {
  const { t } = useI18n()
  const { forceLink } = useBreadcrumb()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function index() {
    return [
      {
        text: t('ttmt.breadcrumbs.claims.claims'),
        link: false,
        to: '/claims',
      },
    ]
  }

  /**
   * Breadcrumb for show page
   *
   * @param claim
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function show(claim) {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: claim.id,
        link: false,
        to: `/claims/${claim.id}`,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for edit page
   *
   * @param claim
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function edit(claim) {
    let breadcrumbs = show(claim)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('ttmt.breadcrumbs.common.edit'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  return {
    index,
    show,
    edit,
  }
}
