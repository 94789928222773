import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormWizard from '@extranet/hooks/formWizard'

export default function useClaimForm(props) {
  const { t } = useI18n()

  // ---------- FORM ----------

  const { form } = useFormWizard(props)

  const countryValue = computed(() => (
    form.value.values.country
  ))

  const companyTypeValue = computed(() => (
    form.value.values.company_type
  ))

  // ---------- COMPANY TYPE ----------

  const isCompany = computed(() => (
    companyTypeValue.value === 'company'
  ))

  const isIndividual = computed(() => (
    companyTypeValue.value === 'individual'
  ))

  const isAssociation = computed(() => (
    companyTypeValue.value === 'association'
  ))

  // ---------- HINTS ----------

  /**
   * Get translation for a specific hint depending on country
   *
   * @param hint
   *
   * @returns {string}
   */
  function getHintTranslation(hint) {
    return t(`ttmt.claims.form_wizard.documents.${countryValue.value}.${hint}`)
  }

  const hintCompanyRegistrationDocument = computed(() => (
    getHintTranslation('company_registration_document')
  ))

  const hintCompanyStatutes = computed(() => (
    getHintTranslation('company_statutes')
  ))

  const hintCompanyLegalRepresentativeIdentityPiece = computed(() => (
    getHintTranslation('company_legal_representative_identity_piece')
  ))

  const hintIndividualFirstIdentityPiece = computed(() => (
    getHintTranslation('individual_first_identity_piece')
  ))

  const hintIndividualSecondIdentityPiece = computed(() => (
    getHintTranslation('individual_second_identity_piece')
  ))

  const hintIndividualRegistrationDocument = computed(() => (
    getHintTranslation('individual_registration_document')
  ))

  const hintAssociationStatutes = computed(() => (
    getHintTranslation('association_statutes')
  ))

  const hintAssociationLegalRepresentativeIdentityPiece = computed(() => (
    getHintTranslation('association_legal_representative_identity_piece')
  ))

  watch(countryValue, (newValue) => {
    if (!form.value.values.phone) {
      form.value.setFieldValue(
        'phone_country_code',
        newValue,
      )
    }
  })

  return {
    countryValue,
    companyTypeValue,
    isCompany,
    isIndividual,
    isAssociation,
    hintCompanyRegistrationDocument,
    hintCompanyStatutes,
    hintCompanyLegalRepresentativeIdentityPiece,
    hintIndividualFirstIdentityPiece,
    hintIndividualSecondIdentityPiece,
    hintIndividualRegistrationDocument,
    hintAssociationStatutes,
    hintAssociationLegalRepresentativeIdentityPiece,
  }
}
