<template>
  <a
    v-if="link"
    class="hover:text-theme-500"
    :href="`${refPrefix ? refPrefix : ''}${link}`"
    :target="!refPrefix ? '_blank' : '_self'"
  >
    {{ link }}
  </a>

  <p
    v-else
    class="italic"
  >
    {{ t('ttmt.common.not_specified') }}
  </p>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  // Link type (mailto, tel...)
  type: {
    type: String,
    default: 'url',
  },
  // Link to use (URL, phone no...)
  link: {
    type: String,
    default: null,
  },
})

const { t } = useI18n()

const refPrefix = computed(() => {
  switch (props.type) {
    case 'phone':
      return 'tel:'
    case 'email':
      return 'mailto:'
    default:
      return null
  }
})
</script>
