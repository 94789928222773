<template>
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
    <app-card-item
      class="p-4"
    >
      <template v-if="isPending">
        <form-group
          rules="required"
          name="country"
          type="select"
          :label="t('validation.attributes.country')"
          :form-control-props="{
            options: claimCountriesOptions,
          }"
        />

        <form-group
          rules="required"
          name="company_type"
          type="select"
          :label="t('validation.attributes.company_type')"
          :form-control-props="{
            options: claimCompanyTypesOptions,
          }"
        />

        <form-group
          rules="required|phone"
          name="phone"
          type="tel"
        />

        <form-group
          rules="required|email"
          name="email"
          type="email"
        />

        <form-group
          name="state"
          type="hidden"
        />
      </template>

      <template v-else>
        <!-- Country -->
        <div>
          <p class="font-bold text-lg mb-1">
            {{ capitalize(t('validation.attributes.country')) }}
          </p>

          <p>
            {{ t(`enums.App\\Enums\\Claim\\ClaimCountriesEnum.${resource.attributes?.country}`) }}
          </p>
        </div>

        <!-- Company type -->
        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.company_type')) }}
          </p>

          <p>
            {{ t(`enums.App\\Enums\\Claim\\ClaimCompanyTypeEnum.${resource.attributes?.company_type}`) }}
          </p>
        </div>

        <!-- Phone -->
        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.phone')) }}
          </p>

          <app-data-grid-link
            type="phone"
            :link="resource?.attributes?.phone"
          />
        </div>

        <!-- Email -->
        <div>
          <p class="font-bold text-lg mb-1 mt-6">
            {{ capitalize(t('validation.attributes.email')) }}
          </p>

          <app-data-grid-link
            type="email"
            :link="resource?.attributes?.email"
          />
        </div>
      </template>
    </app-card-item>

    <app-card-item
      class="p-4"
    >
      <!-- Company -->
      <form-group
        v-if="isCompany"
        name="company_registration_document"
        type="file"
        :hint="hintCompanyRegistrationDocument"
        :form-control-props="{
          mimeTypesAccepted: documentMimeTypes,
          s3Private: true,
          filePreviewCallback: handleFilePreview
        }"
      />

      <!-- Individual -->
      <form-group
        v-if="isIndividual"
        name="individual_registration_document"
        type="file"
        :hint="hintIndividualRegistrationDocument"
        :form-control-props="{
          mimeTypesAccepted: documentMimeTypes,
          s3Private: true,
          filePreviewCallback: handleFilePreview
        }"
      />

      <!-- Association -->
      <form-group
        v-if="isAssociation"
        name="association_statutes"
        type="file"
        :hint="hintAssociationStatutes"
        :form-control-props="{
          mimeTypesAccepted: documentMimeTypes,
          s3Private: true,
          filePreviewCallback: handleFilePreview
        }"
      />
    </app-card-item>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

import { showClaimFile } from '@shared/http/api'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import useClaimForm from '@extranet/hooks/claimForm'
import useDocuments from '@extranet/hooks/documents'
import FormGroup from '@shared/components/form/FormGroup.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppDataGridLink from '@extranet/components/ui/data_grid/AppDataGridLink.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const {
  claimCountriesOptions,
  claimCompanyTypesOptions,
} = useSelectOptions()
const {
  isCompany,
  isIndividual,
  isAssociation,
  hintCompanyRegistrationDocument,
  hintIndividualRegistrationDocument,
  hintAssociationStatutes,
} = useClaimForm({})

// ---------- RULES ----------

const { documentMimeTypes } = useDocuments()

function handleFilePreview(value) {
  showClaimFile(props.resource.id, { fileName: value })
    .then((response) => {
      if (response.data) {
        window
          .open(response.data, '_blank')
          .focus()
      }
    })
}

// ---------- STATES ----------

const isPending = computed(() => (
  props.resource.attributes?.state === 'pending'
))
</script>
