<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <teleport-wrapper to="#mainTitle">
    {{ t('ttmt.breadcrumbs.claims.claims') }}
  </teleport-wrapper>

  <div ref="appTableContainerRef">
    <app-table
      :headers="headers"
      :data="tableData"
      :loading="loading"
    />
  </div>

  <app-pagination
    :links="links"
    :change-page-callback="handleChangePage"
    :element-to-scroll-to="appTableContainerRef"
    class="mt-5"
  />
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
} from 'vue'
import { useI18n } from 'vue-i18n'

import { fetchClaims as fetchApiClaims } from '@shared/http/api'
import useClaim from '@extranet/hooks/claim'
import useDatetime from '@shared/hooks/datetime'
import breadcrumbClaim from '@extranet/breadcrumbs/claim'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppPagination from '@extranet/components/ui/AppPagination.vue'
import AppStatusBadge from '@shared/components/ui/AppStatusBadge.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'
import ClaimTableActions from '@extranet/components/resources/claim/ClaimTableActions.vue'

const { t } = useI18n()
const { statusBadgeDesign } = useClaim({})
const { formatDateWithTime } = useDatetime()

// ---------- CLAIMS ----------

const nextPageFetching = ref(false)
const claims = ref([])
const loading = ref(true)

function fetchClaims(nextPage = false) {
  loading.value = !nextPage
  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
  }

  fetchApiClaims(params)
    .then((response) => {
      const oldClaims = claims.value
      const newClaims = response.data.data?.map((claim) => ({
        resource: claim,
      })) ?? []

      claims.value = nextPage
        ? [...oldClaims, ...newClaims] // add new results after the old ones
        : newClaims // replace the old results with the new ones

      // Pagination links
      links.value = response.data.meta?.links ?? []
    })
    .finally(() => {
      nextPageFetching.value = false
      loading.value = false
    })
}

// ---------- DATA TABLE ----------

const headers = [
  { label: t('validation.attributes.point_of_interest') },
  { label: t('validation.attributes.state') },
  { label: t('validation.attributes.validated_at') },
  { label: t('validation.attributes.created_at') },
  { label: t('validation.attributes.actions') },
]

const tableData = computed(() => (
  claims?.value?.map((claim) => ({
    poi: {
      component: PointOfInterestWithMainPicture,
      props: {
        resource: claim.resource?.relationships?.point_of_interest,
      },
    },
    state: {
      component: AppStatusBadge,
      props: {
        text: t(`ttmt.states.claim_state.${claim.resource?.attributes?.state}`),
        design: statusBadgeDesign(claim.resource?.attributes?.state),
      },
    },
    validated_at: claim.resource?.attributes?.validated_at !== null
      ? formatDateWithTime(claim.resource?.attributes?.validated_at)
      : null,
    created_at: formatDateWithTime(claim.resource?.attributes?.created_at),
    actions: {
      component: ClaimTableActions,
      props: {
        resource: claim.resource,
      },
    },
  }))
))

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const links = ref([])
const page = ref(1)

function handleChangePage(value) {
  page.value = value
  fetchClaims()
}

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbClaim()

const breadcrumbs = computed(() => (
  index()
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchClaims()
})
</script>
