<template>
  <div
    :class="`md:grid md:grid-cols-3 hover:bg-gray-50 md:space-y-0 space-y-1 p-4 ${borders}`"
  >
    <p class="text-theme-500">
      {{ label }}
    </p>

    <div class="col-span-2">
      <component
        :is="line.component"
        v-bind="line.props"
        v-if="line.component"
      />

      <template
        v-else
      >
        <template
          v-if="line.data"
        >
          {{ line.data }}
        </template>

        <p
          v-else
          class="italic"
        >
          {{ t('ttmt.common.not_specified') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { capitalize } from 'lodash'

const props = defineProps({
  // Data line
  line: {
    type: Object,
    default: () => ({}),
  },
  // Display border
  showBorder: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()

// ---------- LABEL ----------

const label = computed(() => {
  // If "capitalizedLabel" explicitely set to false, do not capitalize
  if (props.line.capitalizedLabel === false) {
    return props.line.label
  }

  // Capitalize by default
  return capitalize(props.line.label)
})

// ---------- STYLE ----------

const borders = computed(() => (
  props.showBorder
    ? 'border-b'
    : null
))
</script>
